import React, { useRef, useEffect } from "react";
import "./Hero.css"
import { TweenMax, Power1 } from 'gsap'

export default function HeroSmall(props) {
    let imageItem = useRef(null);
    let textItem = useRef(null)
    useEffect(() => {
        TweenMax.from(
            imageItem,
            .8,
            {
                opacity: 0,
                ease: Power1.easeIn,
            }
        )
        TweenMax.from(
            textItem,
            .8,
            {
                opacity: 0,
                ease: Power1.easeIn,
            }
        )
        TweenMax.to(
            imageItem,
            .8,
            {
                opacity: 1,
                y: -20,
                ease: Power1.easeIn,
            }
        )
        TweenMax.to(
            textItem,
            .8,
            {
                opacity: 1,
                y: -20,
                ease: Power1.easeIn,
            }
        )
    }, [])

    return (
        <div className="hero-container">
            <div ref={el => { textItem = el }} className="right-container">
                <h1>{props.intro}</h1>
                <h2>{props.subheading}</h2>
                <p>{props.textOne}</p>
                <p>{props.textTwo}</p>
                <p>{props.textThree}</p>
                <p>{props.textFour}</p>
            </div>

            <div className="left-container">
                <div className="image-container">
                    <img ref={el => { imageItem = el }} className="about-image" src={props.img} alt="About" />
                </div>
            </div>
        </div>
    )

};

