import React from 'react';
import Hero from "../components/Hero/Hero"
import ServicesHome from "../components/ServicesHome/ServicesHome"
import IntroServices from "../components/ServicesIntro/IntroServices"
import ContactBar from "../components/ContactBlock/ContactBlock"
import Flower from "../assets/home.jpg"
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";


export default function Home() {

    const history = useHistory();
    function handleClick(e) {
        e.preventDefault()
        history.push("contact");
    }

    return (

        <div>

            <Hero
                intro={"Welcome to RB Counselling"}
                subheading={"Confidential, supportive counselling for adults and adolescents in Watford, St Albans and North West London."}
                textOne={"Are you facing difficulties or challenges in your life, struggling to come to terms with something you are going through or have been through in the past?"}
                textTwo={"Maybe you are experiencing feelings of stress, worry, anxiety, anger or sadness and struggling with self-confidence, self-doubt, feeling worthless or not being good enough. These feelings may be taking over and stopping you from living your life the way you would like. They may be affecting your personal, work or family life in a negative way and leaving you feeling out of control, alone and confused."}
                textThree={"I can help you make sense of what you are feeling, build self-esteem, manage your emotions and take back control of your life."}
                img={Flower} />
            <IntroServices />
            <ServicesHome />
            <ContactBar
                title={"Contact Me"}
                text={"Contact me now to book a complimentary 15 minute telephone consultation to discuss how counselling can help you."}
                ButtonText={"Contact Me"}
                click={handleClick}
            />
            <Helmet>
                <title>Roxanne Bigwood Counselling</title>
                <meta name="description" content="Roxanne Bigwood Counselling offers confidential, supportive sounselling for adults and adolescents" />
                <meta name="keywords" content="Counselling, Therapy, Watford, St Albans, Mental Health" />
            </Helmet>
        </div>
    )
};

