import React from "react";
import "./Address.css"
import address from "../../assets/address.png"



const Location = () => {


    return (
        <>

            <div className="address-container">
                <h2>My clincs are located at the below addresses</h2>
                <div class="locations">
                    <a href="https://goo.gl/maps/pgBHytuYCMkSZNmB6" target="_blank" rel="noopener noreferrer">
                        <div className="address-wrapper">
                            <img className="address-icon" src={address} alt="Icon" />
                            <p>Better Care Clinic, 53a Queens Road, Watford, WD18 0RD</p>
                        </div>
                    </a>
                    <a href="https://goo.gl/maps/DYgUf2metEjaaRJ47" target="_blank" rel="noopener noreferrer">
                        <div className="address-wrapper">
                            <img className="address-icon" src={address} alt="Icon" />
                            <p>Canberra House, 4a, London Road, St Albans AL1 1LE</p>

                        </div>
                    </a>
                    <a href="https://goo.gl/maps/qNWHuhPydYYu6WscA" target="_blank" rel="noopener noreferrer">
                        <div className="address-wrapper">
                            <img className="address-icon" src={address} alt="Icon" />
                            <p>Greenlands Lane, Hendon, London NW4 1RL</p>
                        </div>
                    </a>
                </div>
            </div>


        </>
    )
};

export default Location;



