import React from "react"
import "./footer.css"
import Bacp from "../../assets/bacp.png"
import Facebook from "../../assets/facebook.png"
import Instagram from "../../assets/instagram.png"
import Verified from "../../assets/verified.jpeg"
import Covid from "../../assets/thumbnail.png"

export default function Footer() {
    return (
        <footer className="footer-main">
            <div className="footer-container">

                <div className="item">
                    <h3 className="title">Get in touch</h3>
                    <p>roxanne.bigwood@rbcounselling.co.uk </p>
                </div>

                <div className="item">
                    <h3 className="title">Follow Me </h3>
                    <a href="https://www.facebook.com/RBCounselling.co.uk" target="_blank" rel="noopener noreferrer"><img className='social-icon' src={Facebook} alt="Facebook Icon"></img></a>
                    <a href="https://www.instagram.com/roxannebigwood_counselling_/" target="_blank" rel="noopener noreferrer"><img className='social-icon' src={Instagram} alt="Instagram"></img></a>
                </div>

                <div className="logos">
                    <h3 className="title">Certifications</h3>
                    <a href="https://www.bacp.co.uk/profile/d369a27c-2e58-e811-8106-3863bb351d40/therapist?location=Watford" target="_blank" rel="noopener noreferrer"><img src={Bacp} className="bcap-logo" alt="Bacp-logo"></img></a>
                    <a href="https://www.psychologytoday.com/gb/counselling/roxanne-bigwood-st-albans-eng/454170" target="_blank" rel="noopener noreferrer"><img src={Verified} className="psychology" alt="Psychology-today"></img></a>
                    <img src={Covid} className="covid" alt="Covid"></img>
                </div>
            </div>
            <div class = "copyright">
            <p class="copyright-content">© RB Counselling 2020. Website by <a href="https://www.creativeform.co.uk" target="_blank" rel="noopener noreferrer">Creative Form</a> </p>
            </div>
        </footer>

    )
};

