import React from 'react';
import HeroSmall from "../components/HeroSmall/HeroSmall"
import Phone from "../assets/phone.png"
import Email from "../assets/email.png"
import Laptop from "../assets/contact.jpg"
import ContactBar from "../components/ContactBlock/ContactBlock"
import Location from "../components/Address/Address"
import { Helmet } from "react-helmet";

export default function Contact() {

    function handleClick(e) {
        e.preventDefault()
        window.location.href = "mailto:roxanne.bigwood@rbcounselling.co.uk?subject=Consultation";
    }

    return (
        <div>
            <HeroSmall
            intro={"Get in Touch"}
            textThree={"You can call or email me to arrange an appointment in total confidence and let us begin the journey of healing together. A list of my clinics can also be found below."}
            imgOne={Phone}
            textOne={"Tel: 07857 836 390"}
            imgTwo={Email}
            textTwo={"Email me at roxanne.bigwood@rbcounselling.co.uk"}
            img={Laptop}
            />
            <Location/>
            <ContactBar
            title={"Free Consultation"}
            text={"Contact me now to book a complimentary 15 minute telephone consultation to discuss how counselling can help you."}
            ButtonText={"Contact Me"}
            click={handleClick} 
            />

            <Helmet>
            <title>Contact</title>
            <meta name="description" content="Contact me for a free Therapy Consultation" />
            <meta name="keywords" content="Counselling, Therapy, Watford, St Albans, Mental Health" />
          </Helmet>
        </div>

    )
}

