import React from "react";
import "./AboutMe.css";
import Check from "../../assets/check.png"


const Abouthome = () => {
 
 
    return (
        <>

            <div className="about-services">

                <div className="center-container fadeIn">
                    <div className="left-about-container">
                        <h2>About Me</h2>
                        <p>I decided to train as a counsellor after having a positive experience with my own counselling at a challenging time in my life. I felt inspired to help and support other people through difficulties and they are facing in their lives. I decided to change my career and embark on my counselling training journey. I am passionate about supporting people through difficult and challenging times and helping them find their inner strength and confidence and move forward with their lives. I feel my life experiences have helped me to be the person I am and enable me to be able to connect well with people of all ages and backgrounds.</p>
                        <p>Alongside my counselling practice, I work in a counselling agency where I help clients with a wide range of issues, and I am also a school counsellor working with adolescents in a secondary school. I have an advanced DBS and have completed training in Safeguarding and Prevent.</p>
 
                    </div>
                    <div className="middle-about-container">
                        <h2>Qualifications and Training</h2>
                        <div className = "box">
                       <ul>
                       <li>CPCAB Level 2 Certificate in Counselling Skills</li>
                       <li>CPCAB Level 3 Certificate in Counselling Studies</li>
                       <li>BTEC Level 5 Diploma in Therapeutic Counselling</li>
                       <li>Counselling Online - a Coronavirus Primer course</li>
                       <li>Solution Focused Brief Therapy</li>
                       </ul>
                        </div>

                    </div>

                </div>
                <div className = "training">
                <h3>Further Training</h3>
                <p className="qualification">Along with my qualifications I have also taken further training in the below areas.</p>
                <div className="center-container">
                <div className="middle-services-container">
                   <ul>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Trauma</li>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Abuse</li>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Self-Harm</li>
   
                   </ul>
                </div>
                <div className="left-services-container">
                   <ul>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Suicidal Thoughts</li>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Anxiety Disorders </li>
                      <li className="services-box"><img className="check" src={Check} alt="Check" />Dissociative Disorders</li>

                   </ul>
                </div>
             </div>
                </div>
            </div>
        </>
    )
};

export default Abouthome;
