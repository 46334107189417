import React from 'react';
import Hero from "../components/Hero/Hero"
import AboutMe from "../components/About/AboutMe"
import Roxanne from "../assets/rox.JPG"
import { Helmet } from "react-helmet";

export default function AboutPage() {

    return (
        <>
            <Hero
                intro={"Hello"}
                textTwo={"My name is Roxanne. I am a fully qualified, Person Centred Adult and Adolescent Counsellor working privately with clients in Watford, St Albans, and North West London and across the UK. I am a registered member of the British Association of Counselling and Psychotherapy (BACP). I adhere to their high standards of proficiency, professionalism, and good ethical practice."}
                textThree={"I am passionate about supporting individuals through difficulties and challenges they are facing in their lives. My range of experience is wide, with a special interest in supporting adults and young people with invisible health conditions such as Fibromyalgia, Ehlers Danlos and other Chronic illnesses and disabilities, as well as people who are struggling with the effects of trauma and abuse."}
                img={Roxanne}
            />
            <AboutMe />
            <Helmet>
                <title>About Me</title>
                <meta name="description" content="Qualified Person Centred Adult and Adolescent Counsellor; registered member of the British Association of Counselling and Psychotherapy (BACP) " />
                <meta name="keywords" content="Counselling, Therapy, Bacp, Depression, Anxiety" />
            </Helmet>
        </>
    )
};

