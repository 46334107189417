import "./Toolbar.css"
import ToggleButton from "./ToggleButton"
import React from "react";
import { NavLink } from 'react-router-dom'
import Logo from "../../assets/logo.png"


const toolbar = props => (

    <header className="toolbar">
    <nav className="toolbar__navigation">
    <div className="toolbar__toggle-button">
    <ToggleButton click = {props.drawClickHandler}/>
    </div>
   

    <div className="toolbar_navigation-items">
 
    <ul>
    <li className='line'><NavLink exact to= "/" activeClassName="active">Home</NavLink></li>
    <li ><NavLink to="/about"  activeClassName="active"> About</NavLink></li>
    </ul>
    </div>
    <div className="toolbar__logo">
     <a href="/"> <img className="logo-image" src={Logo} alt="About"/></a>
     </div>
     <div className="toolbar_navigation-items">
     <ul>
    <li><NavLink to="/services"  activeClassName="active">Services and Fees</NavLink></li>
    <li><NavLink to="/contact"  activeClassName="active">Contact</NavLink></li>
    </ul>
    </div>
 
    </nav>
    
    </header>
);


export default toolbar;

