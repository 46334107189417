import React from 'react';
import Hero from "../components/Hero/Hero"
import Birds from "../assets/birds.jpg"
import { Helmet } from "react-helmet";


function Error() {
 
    return (
        <div>
      
            <Hero
                intro={"Page Not Found"}
                textTwo={"The page you are looking for cannot be found."}
                textThree={"Go back to the Home page."}
                img={Birds}
             
            />
            <Helmet>
            <title>Not Found</title>
          </Helmet>
        </div>
    )
}

export default Error

// <div className="button-container">
// <NavLink to="/"><button>Go Home</button></NavLink>
// </div>