import React from "react";
import "./ContactBlock.css"

export default function ContactBlock(props) {
    return (
        <div className="contact-container">
            <div className="contact-right">
                <h4>{props.title}</h4>
                <p>{props.text}</p>
               <button onClick={props.click}>{props.ButtonText}</button>
            </div>
        </div>
    )
};


