import React from 'react';
import Fees from "../components/Terms/fees"
import Hero from "../components/Hero/Hero"
import Talk from "../components/Walk/talk"
import Room from "../assets/room.JPG"
import { Helmet } from "react-helmet";


export default function Services() {
  return (
    <div>
      <Hero
        intro={"Services"}
        textOne={"My Counselling rooms are located in Watford Town Centre, St Albans and Hendon and are easily assessable by public transport and car. All locations have carparks close by."}
        textTwo={"My Watford room is located on the ground floor and is wheelchair assessable. As well as face to face sessions, I also offer online video counselling for people who may not be able to get to my therapy rooms due to time, location or mobility issues."}
        img={Room}
      />
      <Talk />
      <Fees />
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Counselling and Therapy for different areas of your life" />
        <meta name="keywords" content="Counselling, Therapy, Watford, St Albans, Mental Health" />
      </Helmet>
    </div>
  )
};


