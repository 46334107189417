import React from "react";
import "./ServicesHome.css";
import Check from "../../assets/check.png"


export default function Serviceshome() {
   return (
      <>
         <div className="home-services">
            <div className="right-services-container">
               <h2>More areas I can help with</h2>
               <p></p>
            </div>
            <div className="center-container">
               <div className="middle-services-container">
                  <ul>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Anger Management </li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Loss of Identity</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Self-Injury</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Relationship Issues & Divorce</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Exam Stress </li>
                  </ul>
               </div>
               <div className="left-services-container">
                  <ul>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Eating Disorders</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Substance Abuse</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Long Term Health Conditions</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Mental Health Conditions</li>
                     <li className="services-box"><img className="check" src={Check} alt="Check" />Bereavement</li>
                  </ul>
               </div>
            </div>
         </div>
      </>
   )
};

