import React, { useRef, useEffect } from "react";
import "./HeroSmall.css"
import { TweenMax, Power1 } from 'gsap'

export default function HeroSmall(props) {
    let imageItem = useRef(null);
    let textItem = useRef(null)
    useEffect(() => {
        TweenMax.from(
            imageItem,
            .8,
            {
                opacity: 0,
                ease: Power1.easeIn,
            }
        )
        TweenMax.from(
            textItem,
            .8,
            {
                opacity: 0,
                ease: Power1.easeIn,
            }
        )
        TweenMax.to(
            imageItem,
            .8,
            {
                opacity: 1,
                y: -20,
                ease: Power1.easeIn,
            }
        )
        TweenMax.to(
            textItem,
            .8,
            {
                opacity: 1,
                y: -20,
                ease: Power1.easeIn,
            }
        )
    }, [])

    return (
        <div className="about-hero">
            <div ref={el => { textItem = el }} className="right-hero-container">
                <h1>{props.intro}</h1>
                <h2>{props.subheading}</h2>
                <p>{props.textThree}</p>
                <div className="wrapper">
                    <img ref={el => { imageItem = el }} className="icon-image" src={props.imgOne} alt="Icon" />
                    <p>{props.textOne}</p>
                </div>
                <div className="wrapper">
                    <img ref={el => { imageItem = el }} className="icon-image" src={props.imgTwo} alt="Icon" />
                    <p>{props.textTwo}</p>
                </div>
                <div className="wrapper">
                    <h3>{props.thirdHeading}</h3>
                </div>
            </div>
            <div className="left-hero-container">
                <div className="image-container">
                    <img ref={el => { imageItem = el }} className="about-image" src={props.img} alt="About" />
                </div>
            </div>
        </div>
    )
};

