import React from 'react';
import "./SideDrawer.css"
import {Link} from "react-router-dom";


const Sidebar = (props) => {

    let drawerClasses = 'side-drawer';
    if(props.show){
        drawerClasses = 'side-drawer open';
    } 

return (
   <div className="sideContainer">
    <nav className={drawerClasses}>
    <ul>
   
    <li><Link to="/">Home</Link></li>
    <li><Link to="/about">About</Link></li>
    <li><Link to="/services">Services</Link></li>
    <li><Link to="/Contact">Contact</Link></li>
    </ul>
</nav>
</div>
    );
};

export default Sidebar;

    