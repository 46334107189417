import "./IntroServices.css";
import Check from "../../assets/check.png"
import React, { useRef } from "react";
import { useIntersection } from "react-use";
import gsap from "gsap";

const Serviceshome = () => {

     // Ref for our element
     const sectionRef = useRef(null);
     // All the ref to be observed
     const intersection = useIntersection(sectionRef, {
         root: null,
         rootMargin: "0px",
         threshold: 0.8,
     });
 
     // Animation for fading in
     const fadeIn = element => {
         gsap.to(element, 1, {
             opacity: 1,
             y: -60,
             ease: "power4.out",
             stagger: {
                 amount: 0.3
             }
         });
     };
     // Animation for fading out
     const fadeOut = element => {
         gsap.to(element, 1, {
             opacity: 0,
             y: -20,
             ease: "power4.out"
         });
     };
 
 
 
     // checking to see when the vieport is visible to the user
     intersection && intersection.intersectionRatio < 0.8
         ? fadeOut(".fadeIn")
         : fadeIn(".fadeIn");
 
    return (
        <>

            <div className="intro-services fadeIn">
              
                <div className="center-container fadeIn">
                <div className="left-intro-container">
                <h2>What is Counselling?</h2>
                <p>As a Person-Centred Counsellor, I believe we all born with the ability to make changes and find our own solutions to our problems. Due to the things we experience in life, other people’s views and opinions of us, and our own view of ourselves; this ability can become lost within us. Through the counselling process, I will support and encourage you to find your own ability to help yourself.</p>
                <p>Counselling helps you explore your thoughts and feelings and make sense of what you are going through. It provides you with a confidential, safe space to talk freely about what is troubling you without the fear of being judged. It helps you gain a better understanding of yourself and your experiences and find ways of managing your emotions. It also helps you make long lasting changes, make decisions, cope better and move forward with your life. </p>
                </div>
                    <div className="middle-intro-container">
                    <h2>My specialist areas:</h2>
                        <ul>
                            <li className="services-intro"><img className="check" src={Check} alt="Check" />Anxiety</li>
                            <li className="services-intro"><img className="check" src={Check} alt="Check" />Invisible Health Conditions</li>
                            <li className="services-intro"><img className="check" src={Check} alt="Check" />Depression</li>
                            <li className="services-intro"><img className="check" src={Check} alt="Check" />Low Self-Esteem</li>
                            <li className="services-intro"><img className="check" src={Check} alt="Check" />Trauma and Abuse</li>
                        </ul>
                    </div>
                
                </div>
 
            </div>
        </>
    )
};

export default Serviceshome
