import React from "react";
import "./fees.css"

export default function IntroBlock() {
    return (
        <div className="fees-container">
            <div className="fees-right">
                <h2>Terms and Fees</h2>
            </div>
            <div className="fees-left">
                <ol>
                    <li>Session costs are £60. Some concessions are available. </li>
                    <li>All sessions are 50 minutes and fees are payable 24 hrs in advance by bank transfer. Please let me know if this does not work for you and we can discuss an alternative way of payment.</li>
                    <li>Sessions will be at an agreed day and time each week. Sessions cannot be delayed or extended due to late arrival and will end at the designated time.</li>
                    <li>Full fee will be charged for sessions cancelled with less than 48 hours’ notice.</li>
                    <li>Sessions are mutually agreed based upon your needs, and we would initially offer 6 sessions, after which we can reflect on our sessions and how best to meet your needs.</li>
                    <li>You may end your therapy at any time, and this may be when your goal has been accomplished or any other time. I offer sessions on ending therapy to ensure you move on with the best support.</li>
                </ol>
            </div>
        </div>
    )
}
