
import React, { Component } from "react";
import Toolbar from "./components/Nav/Toolbar";
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from "./components/BackDrop/BackDrop"
import Footer from "./components/Footer/footer"
import Home from "./views/Home"
import About from "./views/About"
import Services from "./views/Services"
import Contact from "./views/Contact"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFoundPage from "./views/404";
import { Helmet } from "react-helmet";


class App extends Component {

  state = {
    sideDrawerOpen: false
  };


  ToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {

    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler}
      />
    }


    return (
      <BrowserRouter>
        <div style={{ height: '100%' }}>
          <Toolbar drawClickHandler={this.ToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          {backdrop}
          <main style={{ marginTop: '100px' }}>

          </main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/About" component={About} />
            <Route path="/Services" component={Services} />
            <Route path="/Contact" component={Contact} />
            <Route path="*" component={NotFoundPage} />
            <Route path="/defaultsite" component={NotFoundPage} />
          </Switch>


          <Footer />
          <Helmet>
            <title>Roxanne Bigwood Counselling</title>
            <meta name="description" content="Roxanne Bigwood counselling provides confidential, supportive sounselling for adults and adolescents" />
            <meta name="keywords" content="Counselling, Therapy, Watford, St Albans, Mental Health" />
          </Helmet>
        </div>
      </BrowserRouter>

    )
  }
}

document.getElementById('react-container')

export default App;

