import React from "react";
import "./talk.css"
import Walk from "../../assets/walk.jpeg"

export default function HeroSmall(props) {

    return (
        <>
       
        <div className="talk">
        <h2>Walk and Talk Therapy</h2>
            <div className="talk-wrapper">
            <p>Walk and Talk Therapy is also available for those who would prefer to be outdoors surrounded by nature.</p>
            <img className="talk-image" src={Walk} alt="Icon" />
        </div>
        </div>
        </>
    )

};



